import React, { useMemo, useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ChampionType } from "../common/models";
import { ddragon } from "../service/ddragon";

type CardProps = {
  champion?: ChampionType; // チャンピオンデータ（ロード中の場合は undefined）
  isLoading: boolean; // ロード中かどうか
};

const Card: React.FC<CardProps> = ({ champion, isLoading }) => {
  const [isAnimated, setIsAnimated] = useState(false); // Generate Team直後のアニメーション再生中のみtrue

  useEffect(() => {
    if (!isLoading) {
      setIsAnimated(true);
      const timeout = setTimeout(() => setIsAnimated(false), 200); // アニメーション後にリセット
      return () => clearTimeout(timeout);
    }
  }, [isLoading]);

  const damageTypeBgColor: Record<ChampionType["damageType"], string> = {
    AD: "bg-red-500",
    AP: "bg-sky-600",
    ADAP: "bg-purple-500",
    NONE: "bg-gray-600",
  };

  const championImageUrl = useMemo(
    () => (champion ? ddragon.getChampionImageUrl(champion.name) : ""),
    [champion?.name]
  );

  // スケルトンのカスタマイズ
  const skeletonBaseColor = "#4B5563"; // bg-gray-700 相当
  const skeletonHighlightColor = "#6B7280"; // bg-gray-500 相当

  return (
    <div
      className={`text-white h-[110px] w-[480px] p-3 bg-gray-800 rounded-lg shadow-md flex transition-transform duration-300 ${
        isAnimated ? "scale-105" : "scale-100"
      }`}
    >
      {/* カードの左側 */}
      <div className="flex flex-col items-center pr-6 pl-1">
        {/* チャンピオン画像 */}
        <div className="w-12 h-12 rounded-full flex-shrink-0 mb-2">
          {isLoading ? (
            <Skeleton
              circle={true}
              width={48}
              height={48}
              baseColor={skeletonBaseColor}
              highlightColor={skeletonHighlightColor}
            />
          ) : (
            <img
              src={championImageUrl}
              alt=""
              className="w-full h-full rounded-full object-cover"
            />
          )}
        </div>

        {/* AP or AD or ADAP */}
        {isLoading ? (
          <Skeleton
            width={60}
            height={24}
            borderRadius={24}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        ) : (
          <div
            className={`text-sm px-3 py-1 rounded-full ${
              damageTypeBgColor[champion!.damageType]
            } text-white font-bold`}
          >
            {champion!.damageType}
          </div>
        )}
      </div>

      {/* カードの右側 */}
      <div className="flex-grow flex flex-col justify-center">
        <div className="text-left mb-4">
          {/* チャンピオン名 */}
          {isLoading ? (
            <Skeleton
              width={120}
              height={24}
              baseColor={skeletonBaseColor}
              highlightColor={skeletonHighlightColor}
            />
          ) : (
            <h2 className="text font-semibold mb-2">{champion!.nameJp}</h2>
          )}

          {/* チャンピオンの特徴(CC,Tankなど) */}
          <div className="flex">
            {isLoading ? (
              <Skeleton
                width={160}
                height={40}
                baseColor={skeletonBaseColor}
                highlightColor={skeletonHighlightColor}
              />
            ) : (
              <>
                {champion!.cc && (
                  <div className="px-3 mr-4 outline outline-1 outline-yellow-500 text-yellow-500 font-bold">
                    CC
                  </div>
                )}
                {champion!.role.includes("Tank") && (
                  <div className="px-3 mr-4 outline outline-1 outline-emerald-500 text-emerald-500 font-bold">
                    Tank
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
