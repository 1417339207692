import { ChampionType } from "../common/models";

const champions: ChampionType[] = [
  {
    "name": "Aatrox",
    "nameJp": "エイトロックス",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Ahri",
    "nameJp": "アーリ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Akali",
    "nameJp": "アカリ",
    "cc": false,
    "damageType": "AP",
    "lane": ["Top","Mid"],
    "role": ["Assassin"]
  },
  {
    "name": "Akshan",
    "nameJp": "アクシャン",
    "cc": false,
    "damageType": "AD",
    "lane": ["Mid"],
    "role": ["Marksman"]
  },
  {
    "name": "Alistar",
    "nameJp": "アリスター",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Tank"]
  },
  {
    "name": "Ambessa",
    "nameJp": "アンベッサ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter","Assassin"]
  },
  {
    "name": "Amumu",
    "nameJp": "アムム",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg"],
    "role": ["Tank"]
  },
  {
    "name": "Anivia",
    "nameJp": "アニビア",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Annie",
    "nameJp": "アニー",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Aphelios",
    "nameJp": "アフェリオス",
    "cc": true,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Ashe",
    "nameJp": "アッシュ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "AurelionSol",
    "nameJp": "オレリオン・ソル",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Aurora",
    "nameJp": "オーロラ",
    "cc": false,
    "damageType": "AP",
    "lane": ["Top","Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Azir",
    "nameJp": "アジール",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Bard",
    "nameJp": "バード",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Enchanter"]
  },
  {
    "name": "Belveth",
    "nameJp": "ベル＝ヴェス",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Blitzcrank",
    "nameJp": "ブリッツクランク",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Tank"]
  },
  {
    "name": "Brand",
    "nameJp": "ブランド",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg","Mid","Sup"],
    "role": ["Mage"]
  },
  {
    "name": "Braum",
    "nameJp": "ブラウム",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Tank"]
  },
  {
    "name": "Briar",
    "nameJp": "ブライアー",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Caitlyn",
    "nameJp": "ケイトリン",
    "cc": true,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Camille",
    "nameJp": "カミール",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Cassiopeia",
    "nameJp": "カシオペア",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Chogath",
    "nameJp": "チョ＝ガス",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Top"],
    "role": ["Tank"]
  },
  {
    "name": "Corki",
    "nameJp": "コーキ",
    "cc": false,
    "damageType": "AD",
    "lane": ["Mid","Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Darius",
    "nameJp": "ダリウス",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Diana",
    "nameJp": "ダイアナ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg","Mid"],
    "role": ["Assassin"]
  },
  {
    "name": "Draven",
    "nameJp": "ドレイヴン",
    "cc": false,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "DrMundo",
    "nameJp": "ドクター・ムンド",
    "cc": false,
    "damageType": "ADAP",
    "lane": ["Top"],
    "role": ["Tank","Fighter"]
  },
  {
    "name": "Ekko",
    "nameJp": "エコー",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg","Mid"],
    "role": ["Assassin"]
  },
  {
    "name": "Elise",
    "nameJp": "エリス",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg"],
    "role": ["Assassin","Mage"]
  },
  {
    "name": "Evelynn",
    "nameJp": "イブリン",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg"],
    "role": ["Assassin"]
  },
  {
    "name": "Ezreal",
    "nameJp": "エズリアル",
    "cc": false,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Fiddlesticks",
    "nameJp": "フィドルスティックス",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg"],
    "role": ["Mage"]
  },
  {
    "name": "Fiora",
    "nameJp": "フィオラ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Fizz",
    "nameJp": "フィズ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Assassin"]
  },
  {
    "name": "Galio",
    "nameJp": "ガリオ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Tank","Mage"]
  },
  {
    "name": "Gangplank",
    "nameJp": "ガングプランク",
    "cc": false,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Garen",
    "nameJp": "ガレン",
    "cc": false,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Gnar",
    "nameJp": "ナー",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Gragas",
    "nameJp": "グラガス",
    "cc": true,
    "damageType": "AP",
    "lane": ["Top","Jg"],
    "role": ["Tank","Mage"]
  },
  {
    "name": "Graves",
    "nameJp": "グレイブス",
    "cc": false,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Marksman"]
  },
  {
    "name": "Gwen",
    "nameJp": "グウェン",
    "cc": false,
    "damageType": "AP",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Hecarim",
    "nameJp": "ヘカリム",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Heimerdinger",
    "nameJp": "ハイマーディンガー",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid","Sup"],
    "role": ["Mage"]
  },
  {
    "name": "Hwei",
    "nameJp": "フェイ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Illaoi",
    "nameJp": "イラオイ",
    "cc": false,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Irelia",
    "nameJp": "イレリア",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top","Mid"],
    "role": ["Fighter"]
  },
  {
    "name": "Ivern",
    "nameJp": "アイバーン",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Jg"],
    "role": ["Enchanter"]
  },
  {
    "name": "Janna",
    "nameJp": "ジャンナ",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Enchanter"]
  },
  {
    "name": "JarvanIV",
    "nameJp": "ジャーヴァンⅣ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Jax",
    "nameJp": "ジャックス",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Jayce",
    "nameJp": "ジェイス",
    "cc": false,
    "damageType": "AD",
    "lane": ["Top","Mid"],
    "role": ["Fighter"]
  },
  {
    "name": "Jhin",
    "nameJp": "ジン",
    "cc": true,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Jinx",
    "nameJp": "ジンクス",
    "cc": true,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Kaisa",
    "nameJp": "カイ＝サ",
    "cc": false,
    "damageType": "ADAP",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Kalista",
    "nameJp": "カリスタ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Karma",
    "nameJp": "カルマ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Sup"],
    "role": ["Mage","Enchanter"]
  },
  {
    "name": "Karthus",
    "nameJp": "カーサス",
    "cc": false,
    "damageType": "AP",
    "lane": ["Jg"],
    "role": ["Mage"]
  },
  {
    "name": "Kassadin",
    "nameJp": "カサディン",
    "cc": false,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Assassin","Mage"]
  },
  {
    "name": "Katarina",
    "nameJp": "カタリナ",
    "cc": false,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Assassin"]
  },
  {
    "name": "Kayle",
    "nameJp": "ケイル",
    "cc": false,
    "damageType": "ADAP",
    "lane": ["Top"],
    "role": ["Marksman"]
  },
  {
    "name": "Kayn",
    "nameJp": "ケイン",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter","Assassin"]
  },
  {
    "name": "Kennen",
    "nameJp": "ケネン",
    "cc": true,
    "damageType": "AP",
    "lane": ["Top"],
    "role": ["Mage"]
  },
  {
    "name": "Khazix",
    "nameJp": "カ＝ジックス",
    "cc": false,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Assassin"]
  },
  {
    "name": "Kindred",
    "nameJp": "キンドレッド",
    "cc": false,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Marksman"]
  },
  {
    "name": "Kled",
    "nameJp": "クレッド",
    "cc": false,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "KogMaw",
    "nameJp": "コグ＝マウ",
    "cc": false,
    "damageType": "ADAP",
    "lane": ["Bot"],
    "role": ["Marksman","Mage"]
  },
  {
    "name": "KSante",
    "nameJp": "カ・サンテ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Tank","Fighter"]
  },
  {
    "name": "Leblanc",
    "nameJp": "ルブラン",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Assassin","Mage"]
  },
  {
    "name": "LeeSin",
    "nameJp": "リー・シン",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Leona",
    "nameJp": "レオナ",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Tank"]
  },
  {
    "name": "Lillia",
    "nameJp": "リリア",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg"],
    "role": ["Fighter","Mage"]
  },
  {
    "name": "Lissandra",
    "nameJp": "リサンドラ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Lucian",
    "nameJp": "ルシアン",
    "cc": false,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Lulu",
    "nameJp": "ルル",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Enchanter"]
  },
  {
    "name": "Lux",
    "nameJp": "ラックス",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid","Sup"],
    "role": ["Mage"]
  },
  {
    "name": "Malphite",
    "nameJp": "マルファイト",
    "cc": true,
    "damageType": "AP",
    "lane": ["Top"],
    "role": ["Tank"]
  },
  {
    "name": "Malzahar",
    "nameJp": "マルザハール",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Maokai",
    "nameJp": "マオカイ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg","Sup"],
    "role": ["Tank"]
  },
  {
    "name": "MasterYi",
    "nameJp": "マスター・イー",
    "cc": false,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Milio",
    "nameJp": "ミリオ",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Enchanter"]
  },
  {
    "name": "MissFortune",
    "nameJp": "ミス・フォーチュン",
    "cc": false,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "MonkeyKing",
    "nameJp": "ウーコン",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Mordekaiser",
    "nameJp": "モルデカイザー",
    "cc": true,
    "damageType": "AP",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Morgana",
    "nameJp": "モルガナ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Sup"],
    "role": ["Mage"]
  },
  {
    "name": "Naafiri",
    "nameJp": "ナフィーリ",
    "cc": false,
    "damageType": "AD",
    "lane": ["Mid"],
    "role": ["Assassin"]
  },
  {
    "name": "Nami",
    "nameJp": "ナミ",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Enchanter"]
  },
  {
    "name": "Nasus",
    "nameJp": "ナサス",
    "cc": false,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Nautilus",
    "nameJp": "ノーチラス",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Tank"]
  },
  {
    "name": "Neeko",
    "nameJp": "ニーコ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid","Sup"],
    "role": ["Mage"]
  },
  {
    "name": "Nidalee",
    "nameJp": "ニダリー",
    "cc": false,
    "damageType": "AP",
    "lane": ["Jg"],
    "role": ["Assassin","Mage"]
  },
  {
    "name": "Nilah",
    "nameJp": "ニーラ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Fighter"]
  },
  {
    "name": "Nocturne",
    "nameJp": "ノクターン",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Assassin","Fighter"]
  },
  {
    "name": "Nunu",
    "nameJp": "ヌヌ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg"],
    "role": ["Tank"]
  },
  {
    "name": "Olaf",
    "nameJp": "オラフ",
    "cc": false,
    "damageType": "AD",
    "lane": ["Top","Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Orianna",
    "nameJp": "オリアナ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Ornn",
    "nameJp": "オーン",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Top"],
    "role": ["Tank"]
  },
  {
    "name": "Pantheon",
    "nameJp": "パンテオン",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top","Mid","Sup"],
    "role": ["Fighter"]
  },
  {
    "name": "Poppy",
    "nameJp": "ポッピー",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top","Jg","Sup"],
    "role": ["Tank","Fighter"]
  },
  {
    "name": "Pyke",
    "nameJp": "パイク",
    "cc": true,
    "damageType": "AD",
    "lane": ["Sup"],
    "role": ["Assassin"]
  },
  {
    "name": "Qiyana",
    "nameJp": "キヤナ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Mid"],
    "role": ["Assassin"]
  },
  {
    "name": "Quinn",
    "nameJp": "クイン",
    "cc": false,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Marksman"]
  },
  {
    "name": "Rakan",
    "nameJp": "ラカン",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Enchanter"]
  },
  {
    "name": "Rammus",
    "nameJp": "ラムス",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg"],
    "role": ["Tank"]
  },
  {
    "name": "RekSai",
    "nameJp": "レク＝サイ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Rell",
    "nameJp": "レル",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Tank"]
  },
  {
    "name": "Renata",
    "nameJp": "レナータ・グラスク",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Enchanter"]
  },
  {
    "name": "Renekton",
    "nameJp": "レネクトン",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Rengar",
    "nameJp": "レンガー",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Assassin"]
  },
  {
    "name": "Riven",
    "nameJp": "リヴェン",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Rumble",
    "nameJp": "ランブル",
    "cc": false,
    "damageType": "AP",
    "lane": ["Top"],
    "role": ["Fighter","Mage"]
  },
  {
    "name": "Ryze",
    "nameJp": "ライズ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Top","Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Samira",
    "nameJp": "サミーラ",
    "cc": false,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Sejuani",
    "nameJp": "セジュアニ",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Jg"],
    "role": ["Tank"]
  },
  {
    "name": "Senna",
    "nameJp": "セナ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Sup"],
    "role": ["Marksman","Enchanter"]
  },
  {
    "name": "Seraphine",
    "nameJp": "セラフィーン",
    "cc": true,
    "damageType": "AP",
    "lane": ["Bot","Sup"],
    "role": ["Mage","Enchanter"]
  },
  {
    "name": "Sett",
    "nameJp": "セト",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Shaco",
    "nameJp": "シャコ",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Jg","Sup"],
    "role": ["Assassin"]
  },
  {
    "name": "Shen",
    "nameJp": "シェン",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Top","Sup"],
    "role": ["Tank"]
  },
  {
    "name": "Shyvana",
    "nameJp": "シヴァーナ",
    "cc": false,
    "damageType": "ADAP",
    "lane": ["Jg"],
    "role": ["Fighter","Mage"]
  },
  {
    "name": "Singed",
    "nameJp": "シンジド",
    "cc": true,
    "damageType": "AP",
    "lane": ["Top"],
    "role": ["Tank","Mage"]
  },
  {
    "name": "Sion",
    "nameJp": "サイオン",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Top"],
    "role": ["Tank","Fighter"]
  },
  {
    "name": "Sivir",
    "nameJp": "シヴィア",
    "cc": false,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Skarner",
    "nameJp": "スカーナー",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Jg"],
    "role": ["Tank","Fighter"]
  },
  {
    "name": "Smolder",
    "nameJp": "スモルダー",
    "cc": false,
    "damageType": "AD",
    "lane": ["Mid","Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Sona",
    "nameJp": "ソナ",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Enchanter"]
  },
  {
    "name": "Soraka",
    "nameJp": "ソラカ",
    "cc": false,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Enchanter"]
  },
  {
    "name": "Swain",
    "nameJp": "スウェイン",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid","Bot","Sup"],
    "role": ["Mage"]
  },
  {
    "name": "Sylas",
    "nameJp": "サイラス",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Fighter","Mage"]
  },
  {
    "name": "Syndra",
    "nameJp": "シンドラ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "TahmKench",
    "nameJp": "タム・ケンチ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Top","Sup"],
    "role": ["Tank"]
  },
  {
    "name": "Taliyah",
    "nameJp": "タリヤ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg","Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Talon",
    "nameJp": "タロン",
    "cc": false,
    "damageType": "AD",
    "lane": ["Jg","Mid"],
    "role": ["Assassin"]
  },
  {
    "name": "Taric",
    "nameJp": "タリック",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Tank"]
  },
  {
    "name": "Teemo",
    "nameJp": "ティーモ",
    "cc": false,
    "damageType": "AP",
    "lane": ["Top","Jg"],
    "role": ["Marksman","Mage"]
  },
  {
    "name": "Thresh",
    "nameJp": "スレッシュ",
    "cc": true,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Tank"]
  },
  {
    "name": "Tristana",
    "nameJp": "トリスターナ",
    "cc": false,
    "damageType": "AD",
    "lane": ["Mid","Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Trundle",
    "nameJp": "トランドル",
    "cc": false,
    "damageType": "AD",
    "lane": ["Top","Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Tryndamere",
    "nameJp": "トリンダメア",
    "cc": false,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "TwistedFate",
    "nameJp": "ツイステッド・フェイト",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage","Marksman"]
  },
  {
    "name": "Twitch",
    "nameJp": "トゥイッチ",
    "cc": false,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Udyr",
    "nameJp": "ウディア",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Top","Jg"],
    "role": ["Fighter","Tank"]
  },
  {
    "name": "Urgot",
    "nameJp": "アーゴット",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Varus",
    "nameJp": "ヴァルス",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Vayne",
    "nameJp": "ヴェイン",
    "cc": true,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Veigar",
    "nameJp": "ベイガー",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Velkoz",
    "nameJp": "ヴェル＝コズ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid","Sup"],
    "role": ["Mage"]
  },
  {
    "name": "Vex",
    "nameJp": "ヴェックス",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Vi",
    "nameJp": "ヴァイ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Viego",
    "nameJp": "ヴィエゴ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Viktor",
    "nameJp": "ビクター",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Vladimir",
    "nameJp": "ブラッドミア",
    "cc": false,
    "damageType": "AP",
    "lane": ["Top","Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Volibear",
    "nameJp": "ボリベア",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Top","Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Warwick",
    "nameJp": "ワーウィック",
    "cc": true,
    "damageType": "ADAP",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Xayah",
    "nameJp": "ザヤ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Xerath",
    "nameJp": "ゼラス",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid","Sup"],
    "role": ["Mage"]
  },
  {
    "name": "XinZhao",
    "nameJp": "シン・ジャオ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Jg"],
    "role": ["Fighter"]
  },
  {
    "name": "Yasuo",
    "nameJp": "ヤスオ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Mid"],
    "role": ["Fighter"]
  },
  {
    "name": "Yone",
    "nameJp": "ヨネ",
    "cc": true,
    "damageType": "AD",
    "lane": ["Top","Mid"],
    "role": ["Fighter"]
  },
  {
    "name": "Yorick",
    "nameJp": "ヨリック",
    "cc": false,
    "damageType": "ADAP",
    "lane": ["Top"],
    "role": ["Fighter"]
  },
  {
    "name": "Yuumi",
    "nameJp": "ユーミ",
    "cc": false,
    "damageType": "NONE",
    "lane": ["Sup"],
    "role": ["Enchanter"]
  },
  {
    "name": "Zac",
    "nameJp": "ザック",
    "cc": true,
    "damageType": "AP",
    "lane": ["Top","Jg"],
    "role": ["Tank"]
  },
  {
    "name": "Zed",
    "nameJp": "ゼド",
    "cc": false,
    "damageType": "AD",
    "lane": ["Mid"],
    "role": ["Assassin"]
  },
  {
    "name": "Zeri",
    "nameJp": "ゼリ",
    "cc": false,
    "damageType": "ADAP",
    "lane": ["Bot"],
    "role": ["Marksman"]
  },
  {
    "name": "Ziggs",
    "nameJp": "ジグス",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid","Bot"],
    "role": ["Mage"]
  },
  {
    "name": "Zilean",
    "nameJp": "ジリアン",
    "cc": true,
    "damageType": "AP",
    "lane": ["Sup"],
    "role": ["Mage"]
  },
  {
    "name": "Zoe",
    "nameJp": "ゾーイ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Mid"],
    "role": ["Mage"]
  },
  {
    "name": "Zyra",
    "nameJp": "ザイラ",
    "cc": true,
    "damageType": "AP",
    "lane": ["Jg","Sup"],
    "role": ["Mage"]
  }
];

export default champions;