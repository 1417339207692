import React, { useState, useRef, useEffect } from "react";
import html2canvas from "html2canvas";
import champions from "./../data/champions";
import Card from "./../components/ChampionDetails";
import { ChampionType } from "../common/models";
import Switch from "../components/Switch";

type TeamGenerateCondition = {
  isCcRequired: boolean;
  isTankRequired: boolean;
  isAdApBalancedRequired: boolean;
};

const isSatisfied = (champions: ChampionType[], condition: TeamGenerateCondition): boolean => {
  // CC条件
  const isCcContained = champions.filter((e) => e.cc).length > 0;

  // Tank条件
  const isTankContained = champions.filter((e) => e.role.includes("Tank")).length > 0;

  // ADAP条件
  const numAd = champions.filter((e) => e.damageType === "AD").length;
  const numAp = champions.filter((e) => e.damageType === "AP").length;
  const numAdAp = champions.filter((e) => e.damageType === "ADAP").length;
  const isAdApBalanced =
    numAd + numAdAp * 0.5 >= 1 &&
    numAp + numAdAp * 0.5 >= 1 &&
    numAd - numAp < 3 &&
    numAp - numAd < 2;

  return (!condition.isCcRequired || isCcContained) &&
    (!condition.isTankRequired || isTankContained) &&
    (!condition.isAdApBalancedRequired || isAdApBalanced);
};

const generateTeam = (condition: TeamGenerateCondition): ChampionType[] => {
  let selected: ChampionType[];

  /* 条件を満たすまでシャッフルを繰り返す */
  while(true) {
    selected = [];
    const shuffled = champions.sort(() => 0.5 - Math.random());
    shuffled.forEach((e) => {
      switch (selected.length) {
        case 0: // TOP
          if (e.lane.includes("Top") && !selected.includes(e)) {
            selected.push(e);
          }
          break;
        case 1: // JG
          if (e.lane.includes("Jg") && !selected.includes(e)) {
            selected.push(e);
          }
          break;
        case 2: // MID
          if (e.lane.includes("Mid") && !selected.includes(e)) {
            selected.push(e);
          }
          break;
        case 3: // BOT
          if (e.lane.includes("Bot") && !selected.includes(e)) {
            selected.push(e);
          }
          break;
        case 4: // SUP
          if (e.lane.includes("Sup") && !selected.includes(e)) {
            selected.push(e);
          }
          break;
      }
    })
    if (isSatisfied(selected, condition)) {
      // 生成した構成が条件を満たしていたら終了
      break;
    }
  }

  return selected;
}

const TeamGenerator = () => {
  const [isCcRequired, setIsCcRequired] = useState<boolean>(true);
  const [isAdApBalancedRequired, setIsAdApBalancedRequired] = useState<boolean>(true);
  const [isTankRequired, setIsTankRequired] = useState<boolean>(true);
  const [selectedChampions, setSelectedChampions] = useState<ChampionType[]>(generateTeam({ isCcRequired, isAdApBalancedRequired, isTankRequired }));
  const [isLoading, setIsLoading] = useState(false);
  const captureRef = useRef<HTMLDivElement>(null);
  const [showMessage, setShowMessage] = useState(false);

  const handleGenerateTeam = () => {
    setIsLoading(true); // ロード開始
    setTimeout(() => {
      const generated = generateTeam({
        isCcRequired,
        isAdApBalancedRequired,
        isTankRequired,
      });
      setSelectedChampions(generated);
      setIsLoading(false); // ロード終了
    }, 1000); // 1秒のラグ
  };

  const handleCopyToClipboard = async () => {
  
    const target = document.getElementById("team-container");
    if (!target) return;

    const canvas = await html2canvas(target, { useCORS: true });

    canvas.toBlob((blob) => {
      if (blob) {
        const clipboardItem = new ClipboardItem({ "image/png": blob });
        navigator.clipboard.write([clipboardItem]);
        // 成功メッセージを表示
        setShowMessage(true);

        // 一定時間後にメッセージを非表示
        setTimeout(() => {
          setShowMessage(false);
        }, 1500); // 2秒後に非表示
      }
    });
  };

  const laneNames = ["TOP", "JG", "MID", "BOT", "SUP"];
  return (
    <div className="container flex flex-col md:flex-row items-start justify-between max-w-screen-lg mx-auto gap-8 p-2">
      {/* 画面左側: チャンピオンカード表示 */}
      <div id="team-container" className="flex flex-col w-full md:w-1/2 bg-gray-900">
        {
          selectedChampions.map((champion, i) => (
          <div className="p-3" key={i}>
            {champion ? (
              <div className="flex items-center">
                <div className="w-20 pr-6 text-lg text-right text-gray-400 font-light italic">
                  {laneNames[i]}
                </div>
                <Card champion={{ ...champion }} isLoading={isLoading} />
              </div>
            ) : (
              <p>Champion not found</p>
            )}
          </div>
          ))
        }
      </div>

      {/* 画面右側: シャッフル操作部分表示 */}
      <div className="p-4 w-full md:w-1/2 flex flex-col gap-4 items-start">
        <div className="flex flex-col gap-2">
          <Switch checked={isCcRequired} onChange={setIsCcRequired} label="CC必須"></Switch>
          <Switch checked={isAdApBalancedRequired} onChange={setIsAdApBalancedRequired} label="AD/APバランス調整"></Switch>
          <Switch checked={isTankRequired} onChange={setIsTankRequired} label="タンク必須"></Switch>
        </div>
        <div>
          <button
            onClick={handleGenerateTeam}
            disabled={isLoading} // ロード中はクリック不可
            className={`text-lg px-5 py-2 outline outline-1 outline-gray-700 text-white rounded-xl transition duration-200 ${
              isLoading
                ? "bg-gray-500 cursor-not-allowed"
                : "hover:bg-gray-800 hover:scale-110 active:scale-100"
            }`}
          >
            {isLoading ? "Loading..." : "Generate Team"}
          </button>
        </div>
        <div className="flex items-center gap-6">
          <button
            onClick={handleCopyToClipboard}
            className="text-lg px-5 py-2 outline outline-1 outline-gray-700 text-white rounded-xl transition duration-200 hover:bg-gray-800 hover:scale-110 active:scale-100"
          >
            画像をコピー
          </button>
          {/* メッセージ表示部分 */}
          <div
            className={`bg-gray-700 text-white px-3 py-1 rounded shadow-lg transition-all duration-4000 ease-in-out ${
              showMessage ? 'opacity-100 translate-y-0' : 'opacity-0 pointer-events-none'
            }`}
          >
            <div className="absolute left-[-10px] top-1/2 -translate-y-1/2 w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[10px] border-r-gray-700"></div>
            コピーしました！
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamGenerator;
