import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="pt-4 flex justify-center items-center">
      <h1 className="text-2xl text-white font-bold">NT.GG</h1>
      <span className='text-white px-6'>LoL Team Generator</span>
    </header>
  );
};

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow p-4">
        {children}
      </main>
      <footer className="p-4 text-center text-white"style={{ color: "#f0f0f0", fontSize: "10px" }}>
        &copy; 2024 NT.GG. NT.GG is not endorsed by Riot Games and does not reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends. <br />
        League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. 
      </footer>
    </div>
  );
};

export default Layout;
