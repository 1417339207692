class DataDragonAPI {
  private patchVersion: string;

  // コンストラクタでパッチバージョンを受け取る
  constructor(patchVersion: string) {
    this.patchVersion = patchVersion;
  }

  // チャンピオン画像URLを取得
  public getChampionImageUrl(championName: string): string {
    try {
      // パッチバージョンを使用してURLを生成
      const championImageUrl = `https://ddragon.leagueoflegends.com/cdn/${this.patchVersion}/img/champion/${championName}.png`;

      console.log('Champion image URL:', championImageUrl);
      return championImageUrl;
    } catch (error) {
      console.error('Error fetching champion image URL:', error);
      throw error;
    }
  }
}

export const ddragon = new DataDragonAPI('14.22.1'); // パッチバージョン指定
